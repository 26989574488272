body{
  background-color: #f5f7fa;
  color:#4e5860;
}
.header{
  display: flex;
  height: 60px;
  background-color: #2D2D2D;
  color:#fff;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 1px 10px rgba(0,0,0,.08);
  .header-title{
    padding-left: 25px;
    line-height: 1;
    font-size: 22px;
  }
}
.box{
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 25px 50px rgba(8,21,66,.06);
  .box-inner{
    padding: 15px;
  }
}
.form-control{
  font-size: .9rem;
  font-weight: 500;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #4e5860;
  border: 1px solid #e9eaee;
  border-radius: .25rem;
  background-color: #fff;
  background-clip: padding-box;
  &:focus{
    box-shadow: none!important;
  }
}
.menu{
  position: absolute;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow: auto;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
  .item{
    border-bottom: 1px solid #e9eaee;
    padding: 5px 10px;
    transition: all ease 0.2s;

    img{
      display: inline-block;
      margin-right: 10px;
      height: 40px;
    }
  }
  .item-highlighted{
    background-color: #f7f7f7;
    cursor: pointer;
  }
}
.selected-tag{
  margin: 10px 5px 10px 0;
  color: #2c3e50;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.06), 0 1px 2px 0 rgba(0,0,0,.05);
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 10px;
  width: 150px;
  position: relative;
  transition: all ease 0.2s;
  &:hover{
    background-color: #f8f8f8;
  }
  .thumb{
    width: 100px;
    border-radius: 0.25rem;

  }
  .selected-title{
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .remove{
    background-color: transparent;
    border:0;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    img{
      opacity: 0.5;
      width: 20px;
    }
  }
  &.pill-tag{
    width: auto;
    display: inline-flex;
    padding: 5px;
    .selected-title{
      padding: 5px;
      display: inline-block;
      margin: 0;
    }
    .remove{
      position: relative;
    }
  }
}
.location-marker{
  width: 20px;
}
.miles{
  width: 50px;
  padding: 0;
  text-align: center;
}
.list-group-item{
  padding: .2rem 1.25rem;
}
.removeLocations{
  background-color: transparent;
  border:0;
  display: inline-block;
  img{
    opacity: 0.5;
    width: 20px;
  }
}
.small-text{
  font-size: 12px;
}

.reach-card {
  margin: 10px 0;
  border:1px solid #e9eaee;
  padding: 25px 15px;
  text-align: center;
  border-radius: 0.25rem;
  background-color: #fefefe;
}
.login-container {
  height: 100vh;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.btn-brand{
  background-color:#b33d3b ;
  color:#fff;
  padding: 15px;
  &:hover{
    background-color: #8a3b39;
    cursor: pointer;
    color: #fff;
  }
}
.modal{
  display: block;
}
label {
  display: block;
}
.error {
  font-size: 12px;
  color: #ff1d17;
}
